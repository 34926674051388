<template>
  <footer class="footer min-w-1400 max-w-1400">
    <div class="footer-content">
      <ul>
        <li>
          <router-link to="/">Sobre la empresa</router-link>
        </li>
        <li>
          <router-link to="/product">Descripción del Producto</router-link>
        </li>
        <li>
          <router-link to="/service">Servicios de préstamo</router-link>
        </li>
        <li>
          <router-link to="/contact">Contáctenos</router-link>
        </li>
        <li>
          <router-link to="/contact">FAQ</router-link>
        </li>
        <li>
          <a href="https://peawm.dealcorpefectivo.com/help/privacy_agreement">Acuerdo de privacidad</a>
        </li>
      </ul>
      <p class="secondLine">
        Copyright © Dealcorp MX, S.A. de C.V., SOFOM, E.N.R.
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #9A4BFE;
  color: white;
  height: 140px;
  border-bottom: 1px solid red;
}

.footer-content {
  font-size: 22px;
  text-align: center;
  margin: 0 auto;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.firstLine {
  width: 1270px;
  height: 20px;
  font-size: 24px;
  font-family: SF Pro, SF Pro;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.secondLine {
  width: 1098px;
  height: 20px;
  font-size: 22px;
  font-family: SF Pro-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 20px;
  margin-left: 60px;
}
p{
  margin-top: 20px;
}
ul {
  display: flex; /* 将 <ul> 元素的子元素设置为 flex 布局 */
  list-style: none; /* 去除默认的列表样式 */
}

ul li {
  display: flex; /* 将 <li> 元素设置为 flex 布局 */
  margin-right: 80px; /* 可以根据需要设置间距 */
  
}
li a{
  font-size: 18px;
  color: #ffffff;
}
ul li:last-child {
  margin-right: 0; /* 最后一个 <li> 元素不设置右边距 */
}

</style>
